import { useMediaQuery } from '@abyss/web/hooks/useMediaQuery';
import { Box } from '@abyss/web/ui/Box';
import { Slide } from '@abyss/web/ui/Carousel';
import { IconMaterial } from '@abyss/web/ui/IconMaterial';
import { Text } from '@abyss/web/ui/Text';
import { Tooltip } from '@abyss/web/ui/Tooltip';
import { useTranslation } from 'react-i18next';

import { phoneOnly } from '../../common/ConstantsStyles';
import { useSnackcardViewAllCount } from '../../hooks/useSnackcardViewAllCount';
import { Constants } from '../Constants';
import { LinkWithTracking } from '../LinkWithTracking/LinkWithTracking';
import {
  mixedResultsSlideStyle,
  slideContainerStyle,
} from '../SnackCard/SnackCard.styles';

type ResultCardViewAllLinksProps = {
  resultCount: number;
  sectionType: string;
  handleViewAllOnClick?: () => void;
  isDisplayVirtualProvider?: boolean;
  isNetworkIndicatorEnabled?: boolean;
  isOutOfCntyIndicatorEnabled?: boolean;
};

export const ResultCardViewAllLinks = ({
  resultCount = 0,
  sectionType,
  handleViewAllOnClick,
  isDisplayVirtualProvider,
  isNetworkIndicatorEnabled,
  isOutOfCntyIndicatorEnabled = false,
}: ResultCardViewAllLinksProps) => {
  const { t } = useTranslation();
  const mobileScreen = useMediaQuery(phoneOnly);
  const { isExpandViewAllEnabled } = useSnackcardViewAllCount();

  const getHeight = () => {
    let css = {};
    if (mobileScreen) {
      css = { height: '210px' };
    } else {
      css = { height: '230px' };
    }

    if (!mobileScreen && isDisplayVirtualProvider) {
      css = { height: '198px' };
    }

    if (mobileScreen && isDisplayVirtualProvider) {
      css = { height: '176px' };
    }

    if (!mobileScreen && isNetworkIndicatorEnabled) {
      css = { height: '250px' };
    }

    return css;
  };

  const getWidthResult = () => {
    const css = {
      'abyss-slide-container': {
        backgroundColor: '$white',
        marginLeft: '16px',
      },
    };

    return css;
  };

  const getSectionType = (sectionType: string) => {
    if (sectionType === Constants.SEARCH_TYPES.PROVIDER) {
      return t('MIXED_PAGE_CTA.PROVIDERS');
    }
    if (sectionType === Constants.SEARCH_TYPES.PROVIDER_GROUP) {
      return t('MIXED_PAGE_CTA.PROVIDER_GROUPS');
    }
    if (sectionType === Constants.SEARCH_TYPES.FACILITY) {
      return t('MIXED_PAGE_CTA.FACILITIES');
    }

    return `${t('All')} ${sectionType}`;
  };

  return (
    <Slide
      css={
        mobileScreen
          ? mixedResultsSlideStyle(
              resultCount,
              resultCount + 1,
              isExpandViewAllEnabled
            )
          : getWidthResult()
      }
    >
      <Slide.Container css={slideContainerStyle}>
        <Box
          css={{
            ...{
              alignItems: 'center',
              boxShadow: 'rgba(25, 25, 26, 0.16) 0px 2px 6px',
              backgroundColor: '$white',
              gap: '8px',
              borderRadius: '12px',
              marginTop: '0px !important',
              marginBottom:
                (isOutOfCntyIndicatorEnabled && '34px') || '23px !important',
              display: 'flex',
              justifyContent: 'center',
            },
            ...getHeight(),
          }}
        >
          <Tooltip
            align="center"
            asChild={false}
            content={`${getSectionType(sectionType)}`}
            position="top"
          >
            <LinkWithTracking
              after={
                <IconMaterial
                  icon="chevron_right"
                  size={(isExpandViewAllEnabled && '$md') || '$xs'}
                />
              }
              analyticsInfo={{
                location: `body:${sectionType} results`,
              }}
              css={{
                cursor: 'pointer',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-end',
                padding: 'auto',
                width: mobileScreen ? '124px' : '148px',
                '&:hover': { borderColor: 'transparent' },
                '& .abyss-insert-element-root': {
                  justifyContent: 'flex-start',
                },
              }}
              data-auto-testid={`cta-carousel-${sectionType}`}
              data-testid={`cta-carousel-${sectionType}`}
              isStandardAnchor
              onClick={handleViewAllOnClick}
            >
              <Text
                color="$interactive1"
                css={{
                  textAlign: 'left',
                  textOverflow: 'ellipsis',
                  overflow: 'hidden',
                }}
                fontWeight="$bold"
              >
                {`${getSectionType(sectionType)}`}
              </Text>
            </LinkWithTracking>
          </Tooltip>
        </Box>
      </Slide.Container>
    </Slide>
  );
};
